<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">
              영업일자
            </div>
            <ul class="content">
              <li class="item date">
                <input-date
                  type="lookup-condition"
                  v-model="searchOptions.bsnDate"
                />
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :is-shortcut-button="true"
              v-on:click.native="searchButtonClicked"
          >
            조회
          </erp-button>
        </div>
      </div>
      <div class="lookup-right">
        <ul class="lookup-button">
          <li class="print">
            <erp-button
                button-div="PRINT"
                @click.native="onPrintButtonClicked"
                :is-shortcut-button="true"
            >
              인쇄
            </erp-button>
          </li>
        </ul>
      </div>
    </div>
    <div class="content-body">
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-caption">
            <div class="caption-navigation">
              <ejs-tab
                ref="divTab"
                :items="tabList"
                :showCloseButton="false"
                heightAdjustMode="Auto"
                overflowMode="Popup"
                @selected="selectTab"
              />
            </div>
          </div>
          <div class="section-body">
            <ReportView ref="reportViewComponent" :isPopup="false"/>
          </div>
        </section>
      </article>
    </div>
  </div>
</template>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import GolfErpAPI from '@/api/v2/GolfErpAPI';

import InputDate from "@/components/common/datetime/InputDate";
import ReportView from '@/components/common/report/ReportView';
import ErpButton from "@/components/button/ErpButton.vue";

import {getFormattedDate} from "@/utils/date";
import {mapGetters} from "vuex";
import {commonCodesGetCommonCode} from "@/utils/commonCodes";

export default {
  name: "IncomeAmountAggregationTable",
  components: {
    InputDate,
    ErpButton,
    ReportView,
  },
  mixins: [commonMixin, confirmDialogMixin],
  data() {
    return {
      tabSelectedIdx: 0,
      searchOptions: {
        bsnDate: null,
      },
      storeData: commonCodesGetCommonCode("STORE_CODE",true),
      tabList: [
        {
          header: {text: "전체"},
          selectedTab: "ALL",
        },
        {
          header: {text: "입장수입"},
          selectedTab: "FEE",
        },
        {
          header: {text: "대여수입"},
          selectedTab: "RENT",
        }
      ],
    };
  },
  created() {
    this.searchOptions.bsnDate = getFormattedDate(new Date());
  },
  computed: {
    ...mapGetters(["username"]),
  },
  mounted() {
    this.searchButtonClicked();
  },
  methods: {
    async searchButtonClicked() {
      const data = await GolfErpAPI.fetchIncomeAmountAggregationTable(this.searchOptions.bsnDate);
      console.log(this.tabSelectedIdx);
      const reportData =
        this.tabSelectedIdx === 1 ?
          {
            feeStatusByPaymt: data.feeStatusByPaymt,
            bsnDate: this.searchOptions.bsnDate,
            dwName: getFormattedDate(this.searchOptions.bsnDate, "dddd"),
            sanctnList: data.sanctnDtls,
          } :
          this.tabSelectedIdx === 2 ?
            {
              sanctnList:data.sanctnDtls,
              bsnDate: this.searchOptions.bsnDate,
              dwName: getFormattedDate(this.searchOptions.bsnDate, "dddd"),
              rentSalesStatus:
                data.productClassList.map(productClass => {
                  return {
                    rentDivName: productClass.name,
                    visit18hPer:
                      data.closeSalesRent
                        .filter(item =>
                          item.bsnDate === this.searchOptions.bsnDate &&
                          item.classCode === productClass.code)
                        .reduce((acc, cur) => acc + cur.visit18hPer, 0),
                    visit9hPer:
                      data.closeSalesRent
                        .filter(item =>
                          item.bsnDate === this.searchOptions.bsnDate &&
                          item.classCode === productClass.code)
                        .reduce((acc, cur) => acc + cur.visit9hPer, 0),
                    visit9hAditPer:
                      data.closeSalesRent
                        .filter(item =>
                          item.bsnDate === this.searchOptions.bsnDate &&
                          item.classCode === productClass.code)
                        .reduce((acc, cur) => acc + cur.visit9hAditPer, 0),
                    netAmt:
                      data.closeSalesRent
                        .filter(item =>
                          item.bsnDate === this.searchOptions.bsnDate &&
                          item.classCode === productClass.code)
                        .reduce((acc, cur) => acc + cur.netAmt, 0),
                    vatAmt:
                      data.closeSalesRent
                        .filter(item =>
                          item.bsnDate === this.searchOptions.bsnDate &&
                          item.classCode === productClass.code)
                        .reduce((acc, cur) => acc + cur.vatAmt, 0),
                    totAmt:
                      data.closeSalesRent
                        .filter(item =>
                          item.bsnDate === this.searchOptions.bsnDate &&
                          item.classCode === productClass.code)
                        .reduce((acc, cur) => acc + cur.totAmt, 0),
                    totAmtByMonth:
                      data.closeSalesRent
                        .filter(item =>
                          item.bsnDate.substring(0, 7) === this.searchOptions.bsnDate.substring(0, 7) &&
                          item.classCode === productClass.code)
                        .reduce((acc, cur) => acc + cur.visit9hAditPer, 0),
                    totAmtByYear:
                      data.closeSalesRent
                        .filter(item =>
                          item.bsnDate.substring(0, 4) === this.searchOptions.bsnDate.substring(0, 4) &&
                          item.classCode === productClass.code)
                        .reduce((acc, cur) => acc + cur.visit9hAditPer, 0),
                    isAggregation: false,
                    isAggregationFirst: false,
                  };
                }).concat(
                  [
                    {
                      rentDivName: "일    계",
                      visit18hPer:
                        data.closeSalesRent
                          .filter(item =>
                            item.bsnDate === this.searchOptions.bsnDate &&
                            data.productClassList.map(productClassItem => productClassItem.code).includes(item.classCode))
                          .reduce((acc, cur) => acc + cur.visit18hPer, 0),
                      visit9hPer:
                        data.closeSalesRent
                          .filter(item =>
                            item.bsnDate === this.searchOptions.bsnDate &&
                            data.productClassList.map(productClassItem => productClassItem.code).includes(item.classCode))
                          .reduce((acc, cur) => acc + cur.visit9hPer, 0),
                      visit9hAditPer:
                        data.closeSalesRent
                          .filter(item =>
                            item.bsnDate === this.searchOptions.bsnDate &&
                            data.productClassList.map(productClassItem => productClassItem.code).includes(item.classCode))
                          .reduce((acc, cur) => acc + cur.visit9hAditPer, 0),
                      netAmt:
                        data.closeSalesRent
                          .filter(item =>
                            item.bsnDate === this.searchOptions.bsnDate &&
                            data.productClassList.map(productClassItem => productClassItem.code).includes(item.classCode))
                          .reduce((acc, cur) => acc + cur.netAmt, 0),
                      vatAmt:
                        data.closeSalesRent
                          .filter(item =>
                            item.bsnDate === this.searchOptions.bsnDate &&
                            data.productClassList.map(productClassItem => productClassItem.code).includes(item.classCode))
                          .reduce((acc, cur) => acc + cur.vatAmt, 0),
                      totAmt:
                        data.closeSalesRent
                          .filter(item =>
                            item.bsnDate === this.searchOptions.bsnDate &&
                            data.productClassList.map(productClassItem => productClassItem.code).includes(item.classCode))
                          .reduce((acc, cur) => acc + cur.totAmt, 0),
                      totAmtByMonth: 0,
                      totAmtByYear: 0,
                      isAggregation: true,
                      isAggregationFirst: true,
                    },
                    {
                      rentDivName: "월    계",
                      visit18hPer:
                        data.closeSalesRent
                          .filter(item =>
                            item.bsnDate.substring(0, 7) === this.searchOptions.bsnDate.substring(0, 7) &&
                            data.productClassList.map(productClassItem => productClassItem.code).includes(item.classCode))
                          .reduce((acc, cur) => acc + cur.visit18hPer, 0),
                      visit9hPer:
                        data.closeSalesRent
                          .filter(item =>
                            item.bsnDate.substring(0, 7) === this.searchOptions.bsnDate.substring(0, 7) &&
                            data.productClassList.map(productClassItem => productClassItem.code).includes(item.classCode))
                          .reduce((acc, cur) => acc + cur.visit9hPer, 0),
                      visit9hAditPer:
                        data.closeSalesRent
                          .filter(item =>
                            item.bsnDate.substring(0, 7) === this.searchOptions.bsnDate.substring(0, 7) &&
                            data.productClassList.map(productClassItem => productClassItem.code).includes(item.classCode))
                          .reduce((acc, cur) => acc + cur.visit9hAditPer, 0),
                      netAmt:
                        data.closeSalesRent
                          .filter(item =>
                            item.bsnDate.substring(0, 7) === this.searchOptions.bsnDate.substring(0, 7) &&
                            data.productClassList.map(productClassItem => productClassItem.code).includes(item.classCode))
                          .reduce((acc, cur) => acc + cur.netAmt, 0),
                      vatAmt:
                        data.closeSalesRent
                          .filter(item =>
                            item.bsnDate.substring(0, 7) === this.searchOptions.bsnDate.substring(0, 7) &&
                            data.productClassList.map(productClassItem => productClassItem.code).includes(item.classCode))
                          .reduce((acc, cur) => acc + cur.vatAmt, 0),
                      totAmt: 0,
                      totAmtByMonth:
                        data.closeSalesRent
                          .filter(item =>
                            item.bsnDate.substring(0, 7) === this.searchOptions.bsnDate.substring(0, 7) &&
                            data.productClassList.map(productClassItem => productClassItem.code).includes(item.classCode))
                          .reduce((acc, cur) => acc + cur.totAmt, 0),
                      totAmtByYear: 0,
                      isAggregation: true,
                      isAggregationFirst: false,
                    },
                    {
                      rentDivName: "년    계",
                      visit18hPer:
                        data.closeSalesRent
                          .filter(item =>
                            item.bsnDate.substring(0, 4) === this.searchOptions.bsnDate.substring(0, 4) &&
                            data.productClassList.map(productClassItem => productClassItem.code).includes(item.classCode))
                          .reduce((acc, cur) => acc + cur.visit18hPer, 0),
                      visit9hPer:
                        data.closeSalesRent
                          .filter(item =>
                            item.bsnDate.substring(0, 4) === this.searchOptions.bsnDate.substring(0, 4) &&
                            data.productClassList.map(productClassItem => productClassItem.code).includes(item.classCode))
                          .reduce((acc, cur) => acc + cur.visit9hPer, 0),
                      visit9hAditPer:
                        data.closeSalesRent
                          .filter(item =>
                            item.bsnDate.substring(0, 4) === this.searchOptions.bsnDate.substring(0, 4) &&
                            data.productClassList.map(productClassItem => productClassItem.code).includes(item.classCode))
                          .reduce((acc, cur) => acc + cur.visit9hAditPer, 0),
                      netAmt:
                        data.closeSalesRent
                          .filter(item =>
                            item.bsnDate.substring(0, 4) === this.searchOptions.bsnDate.substring(0, 4) &&
                            data.productClassList.map(productClassItem => productClassItem.code).includes(item.classCode))
                          .reduce((acc, cur) => acc + cur.netAmt, 0),
                      vatAmt:
                        data.closeSalesRent
                          .filter(item =>
                            item.bsnDate.substring(0, 4) === this.searchOptions.bsnDate.substring(0, 4) &&
                            data.productClassList.map(productClassItem => productClassItem.code).includes(item.classCode))
                          .reduce((acc, cur) => acc + cur.vatAmt, 0),
                      totAmt: 0,
                      totAmtByMonth: 0,
                      totAmtByYear:
                        data.closeSalesRent
                          .filter(item =>
                            item.bsnDate.substring(0, 4) === this.searchOptions.bsnDate.substring(0, 4) &&
                            data.productClassList.map(productClassItem => productClassItem.code).includes(item.classCode))
                          .reduce((acc, cur) => acc + cur.totAmt, 0),
                      isAggregation: true,
                      isAggregationFirst: false,
                    },
                  ]
                ),
              cartRentSalesStatus:
                commonCodesGetCommonCode("PER_DIV", true).map(commonCode => {
                  return {
                    rentDivName: commonCode.comName,
                    visit18hPer:
                      data.closeSalesRent
                        .filter(item =>
                          item.bsnDate === this.searchOptions.bsnDate &&
                          item.perDiv === commonCode.comCode &&
                          item.classCode2 === "CART")
                        .reduce((acc, cur) => acc + cur.visit18hPer, 0),
                    visit9hPer:
                      data.closeSalesRent
                        .filter(item =>
                          item.bsnDate === this.searchOptions.bsnDate &&
                          item.perDiv === commonCode.comCode &&
                          item.classCode2 === "CART")
                        .reduce((acc, cur) => acc + cur.visit9hPer, 0),
                    visit9hAditPer:
                      data.closeSalesRent
                        .filter(item =>
                          item.bsnDate === this.searchOptions.bsnDate &&
                          item.perDiv === commonCode.comCode &&
                          item.classCode2 === "CART")
                        .reduce((acc, cur) => acc + cur.visit9hAditPer, 0),
                    netAmt:
                      data.closeSalesRent
                        .filter(item =>
                          item.bsnDate === this.searchOptions.bsnDate &&
                          item.perDiv === commonCode.comCode &&
                          item.classCode2 === "CART")
                        .reduce((acc, cur) => acc + cur.netAmt, 0),
                    vatAmt:
                      data.closeSalesRent
                        .filter(item =>
                          item.bsnDate === this.searchOptions.bsnDate &&
                          item.perDiv === commonCode.comCode &&
                          item.classCode2 === "CART")
                        .reduce((acc, cur) => acc + cur.vatAmt, 0),
                    totAmt:
                      data.closeSalesRent
                        .filter(item =>
                          item.bsnDate === this.searchOptions.bsnDate &&
                          item.perDiv === commonCode.comCode &&
                          item.classCode2 === "CART")
                        .reduce((acc, cur) => acc + cur.totAmt, 0),
                    totAmtByMonth:
                      data.closeSalesRent
                        .filter(item =>
                          item.bsnDate.substring(0, 7) === this.searchOptions.bsnDate.substring(0, 7) &&
                          item.perDiv === commonCode.comCode &&
                          item.classCode2 === "CART")
                        .reduce((acc, cur) => acc + cur.totAmt, 0),
                    totAmtByYear:
                      data.closeSalesRent
                        .filter(item =>
                          item.bsnDate.substring(0, 4) === this.searchOptions.bsnDate.substring(0, 4) &&
                          item.perDiv === commonCode.comCode &&
                          item.classCode2 === "CART")
                        .reduce((acc, cur) => acc + cur.totAmt, 0),
                    isAggregation: false,
                    isAggregationFirst: false,
                  };
                }).concat(
                  [
                    {
                      rentDivName: "일    계",
                      visit18hPer:
                        data.closeSalesRent
                          .filter(item =>
                            item.bsnDate === this.searchOptions.bsnDate &&
                            item.classCode2 === "CART")
                          .reduce((acc, cur) => acc + cur.visit18hPer, 0),
                      visit9hPer:
                        data.closeSalesRent
                          .filter(item =>
                            item.bsnDate === this.searchOptions.bsnDate &&
                            item.classCode2 === "CART")
                          .reduce((acc, cur) => acc + cur.visit9hPer, 0),
                      visit9hAditPer:
                        data.closeSalesRent
                          .filter(item =>
                            item.bsnDate === this.searchOptions.bsnDate &&
                            item.classCode2 === "CART")
                          .reduce((acc, cur) => acc + cur.visit9hAditPer, 0),
                      netAmt:
                        data.closeSalesRent
                          .filter(item =>
                            item.bsnDate === this.searchOptions.bsnDate &&
                            item.classCode2 === "CART")
                          .reduce((acc, cur) => acc + cur.netAmt, 0),
                      vatAmt:
                        data.closeSalesRent
                          .filter(item =>
                            item.bsnDate === this.searchOptions.bsnDate &&
                            item.classCode2 === "CART")
                          .reduce((acc, cur) => acc + cur.vatAmt, 0),
                      totAmt:
                        data.closeSalesRent
                          .filter(item =>
                            item.bsnDate === this.searchOptions.bsnDate &&
                            item.classCode2 === "CART")
                          .reduce((acc, cur) => acc + cur.totAmt, 0),
                      totAmtByMonth: 0,
                      totAmtByYear: 0,
                      isAggregation: true,
                      isAggregationFirst: true,
                    },
                    {
                      rentDivName: "월    계",
                      visit18hPer:
                        data.closeSalesRent
                          .filter(item =>
                            item.bsnDate.substring(0, 7) === this.searchOptions.bsnDate.substring(0, 7) &&
                            item.classCode2 === "CART")
                          .reduce((acc, cur) => acc + cur.visit18hPer, 0),
                      visit9hPer:
                        data.closeSalesRent
                          .filter(item =>
                            item.bsnDate.substring(0, 7) === this.searchOptions.bsnDate.substring(0, 7) &&
                            item.classCode2 === "CART")
                          .reduce((acc, cur) => acc + cur.visit9hPer, 0),
                      visit9hAditPer:
                        data.closeSalesRent
                          .filter(item =>
                            item.bsnDate.substring(0, 7) === this.searchOptions.bsnDate.substring(0, 7) &&
                            item.classCode2 === "CART")
                          .reduce((acc, cur) => acc + cur.visit9hAditPer, 0),
                      netAmt:
                        data.closeSalesRent
                          .filter(item =>
                            item.bsnDate.substring(0, 7) === this.searchOptions.bsnDate.substring(0, 7) &&
                            item.classCode2 === "CART")
                          .reduce((acc, cur) => acc + cur.netAmt, 0),
                      vatAmt:
                        data.closeSalesRent
                          .filter(item =>
                            item.bsnDate.substring(0, 7) === this.searchOptions.bsnDate.substring(0, 7) &&
                            item.classCode2 === "CART")
                          .reduce((acc, cur) => acc + cur.vatAmt, 0),
                      totAmt: 0,
                      totAmtByMonth:
                        data.closeSalesRent
                          .filter(item =>
                            item.bsnDate.substring(0, 7) === this.searchOptions.bsnDate.substring(0, 7) &&
                            item.classCode2 === "CART")
                          .reduce((acc, cur) => acc + cur.totAmt, 0),
                      totAmtByYear: 0,
                      isAggregation: true,
                      isAggregationFirst: false,
                    },
                    {
                      rentDivName: "년    계",
                      visit18hPer:
                        data.closeSalesRent
                          .filter(item =>
                            item.bsnDate.substring(0, 4) === this.searchOptions.bsnDate.substring(0, 4) &&
                            item.classCode2 === "CART")
                          .reduce((acc, cur) => acc + cur.visit18hPer, 0),
                      visit9hPer:
                        data.closeSalesRent
                          .filter(item =>
                            item.bsnDate.substring(0, 4) === this.searchOptions.bsnDate.substring(0, 4) &&
                            item.classCode2 === "CART")
                          .reduce((acc, cur) => acc + cur.visit9hPer, 0),
                      visit9hAditPer:
                        data.closeSalesRent
                          .filter(item =>
                            item.bsnDate.substring(0, 4) === this.searchOptions.bsnDate.substring(0, 4) &&
                            item.classCode2 === "CART")
                          .reduce((acc, cur) => acc + cur.visit9hAditPer, 0),
                      netAmt:
                        data.closeSalesRent
                          .filter(item =>
                            item.bsnDate.substring(0, 4) === this.searchOptions.bsnDate.substring(0, 4) &&
                            item.classCode2 === "CART")
                          .reduce((acc, cur) => acc + cur.netAmt, 0),
                      vatAmt:
                        data.closeSalesRent
                          .filter(item =>
                            item.bsnDate.substring(0, 4) === this.searchOptions.bsnDate.substring(0, 4) &&
                            item.classCode2 === "CART")
                          .reduce((acc, cur) => acc + cur.vatAmt, 0),
                      totAmt: 0,
                      totAmtByMonth: 0,
                      totAmtByYear:
                        data.closeSalesRent
                          .filter(item =>
                            item.bsnDate.substring(0, 4) === this.searchOptions.bsnDate.substring(0, 4) &&
                            item.classCode2 === "CART")
                          .reduce((acc, cur) => acc + cur.totAmt, 0),
                      isAggregation: true,
                      isAggregationFirst: false,
                    }
                  ]
                ),
              greenRepairFeeSalesStatus:
                commonCodesGetCommonCode("PER_DIV", true).map(commonCode => {
                  return {
                    rentDivName: commonCode.comName,
                    visit18hPer:
                      data.closeSalesRent
                        .filter(item =>
                          item.bsnDate === this.searchOptions.bsnDate &&
                          item.perDiv === commonCode.comCode &&
                          item.classCode2 === "REPAIR")
                        .reduce((acc, cur) => acc + cur.visit18hPer, 0),
                    visit9hPer:
                      data.closeSalesRent
                        .filter(item =>
                          item.bsnDate === this.searchOptions.bsnDate &&
                          item.perDiv === commonCode.comCode &&
                          item.classCode2 === "REPAIR")
                        .reduce((acc, cur) => acc + cur.visit9hPer, 0),
                    visit9hAditPer:
                      data.closeSalesRent
                        .filter(item =>
                          item.bsnDate === this.searchOptions.bsnDate &&
                          item.perDiv === commonCode.comCode &&
                          item.classCode2 === "REPAIR")
                        .reduce((acc, cur) => acc + cur.visit9hAditPer, 0),
                    netAmt:
                      data.closeSalesRent
                        .filter(item =>
                          item.bsnDate === this.searchOptions.bsnDate &&
                          item.perDiv === commonCode.comCode &&
                          item.classCode2 === "REPAIR")
                        .reduce((acc, cur) => acc + cur.netAmt, 0),
                    vatAmt:
                      data.closeSalesRent
                        .filter(item =>
                          item.bsnDate === this.searchOptions.bsnDate &&
                          item.perDiv === commonCode.comCode &&
                          item.classCode2 === "REPAIR")
                        .reduce((acc, cur) => acc + cur.vatAmt, 0),
                    totAmt:
                      data.closeSalesRent
                        .filter(item =>
                          item.bsnDate === this.searchOptions.bsnDate &&
                          item.perDiv === commonCode.comCode &&
                          item.classCode2 === "REPAIR")
                        .reduce((acc, cur) => acc + cur.totAmt, 0),
                    totAmtByMonth:
                      data.closeSalesRent
                        .filter(item =>
                          item.bsnDate.substring(0, 7) === this.searchOptions.bsnDate.substring(0, 7) &&
                          item.perDiv === commonCode.comCode &&
                          item.classCode2 === "REPAIR")
                        .reduce((acc, cur) => acc + cur.totAmt, 0),
                    totAmtByYear:
                      data.closeSalesRent
                        .filter(item =>
                          item.bsnDate.substring(0, 4) === this.searchOptions.bsnDate.substring(0, 4) &&
                          item.perDiv === commonCode.comCode &&
                          item.classCode2 === "REPAIR")
                        .reduce((acc, cur) => acc + cur.totAmt, 0),
                    isAggregation: false,
                    isAggregationFirst: false,
                  };
                }).concat(
                  [
                    {
                      rentDivName: "일    계",
                      visit18hPer:
                        data.closeSalesRent
                          .filter(item =>
                            item.bsnDate === this.searchOptions.bsnDate &&
                            item.classCode2 === "REPAIR")
                          .reduce((acc, cur) => acc + cur.visit18hPer, 0),
                      visit9hPer:
                        data.closeSalesRent
                          .filter(item =>
                            item.bsnDate === this.searchOptions.bsnDate &&
                            item.classCode2 === "REPAIR")
                          .reduce((acc, cur) => acc + cur.visit9hPer, 0),
                      visit9hAditPer:
                        data.closeSalesRent
                          .filter(item =>
                            item.bsnDate === this.searchOptions.bsnDate &&
                            item.classCode2 === "REPAIR")
                          .reduce((acc, cur) => acc + cur.visit9hAditPer, 0),
                      netAmt:
                        data.closeSalesRent
                          .filter(item =>
                            item.bsnDate === this.searchOptions.bsnDate &&
                            item.classCode2 === "REPAIR")
                          .reduce((acc, cur) => acc + cur.netAmt, 0),
                      vatAmt:
                        data.closeSalesRent
                          .filter(item =>
                            item.bsnDate === this.searchOptions.bsnDate &&
                            item.classCode2 === "REPAIR")
                          .reduce((acc, cur) => acc + cur.vatAmt, 0),
                      totAmt:
                        data.closeSalesRent
                          .filter(item =>
                            item.bsnDate === this.searchOptions.bsnDate &&
                            item.classCode2 === "REPAIR")
                          .reduce((acc, cur) => acc + cur.totAmt, 0),
                      totAmtByMonth: 0,
                      totAmtByYear: 0,
                      isAggregation: true,
                      isAggregationFirst: true,
                    },
                    {
                      rentDivName: "월    계",
                      visit18hPer:
                        data.closeSalesRent
                          .filter(item =>
                            item.bsnDate.substring(0, 7) === this.searchOptions.bsnDate.substring(0, 7) &&
                            item.classCode2 === "REPAIR")
                          .reduce((acc, cur) => acc + cur.visit18hPer, 0),
                      visit9hPer:
                        data.closeSalesRent
                          .filter(item =>
                            item.bsnDate.substring(0, 7) === this.searchOptions.bsnDate.substring(0, 7) &&
                            item.classCode2 === "REPAIR")
                          .reduce((acc, cur) => acc + cur.visit9hPer, 0),
                      visit9hAditPer:
                        data.closeSalesRent
                          .filter(item =>
                            item.bsnDate.substring(0, 7) === this.searchOptions.bsnDate.substring(0, 7) &&
                            item.classCode2 === "REPAIR")
                          .reduce((acc, cur) => acc + cur.visit9hAditPer, 0),
                      netAmt:
                        data.closeSalesRent
                          .filter(item =>
                            item.bsnDate.substring(0, 7) === this.searchOptions.bsnDate.substring(0, 7) &&
                            item.classCode2 === "REPAIR")
                          .reduce((acc, cur) => acc + cur.netAmt, 0),
                      vatAmt:
                        data.closeSalesRent
                          .filter(item =>
                            item.bsnDate.substring(0, 7) === this.searchOptions.bsnDate.substring(0, 7) &&
                            item.classCode2 === "REPAIR")
                          .reduce((acc, cur) => acc + cur.vatAmt, 0),
                      totAmt: 0,
                      totAmtByMonth:
                        data.closeSalesRent
                          .filter(item =>
                            item.bsnDate.substring(0, 7) === this.searchOptions.bsnDate.substring(0, 7) &&
                            item.classCode2 === "REPAIR")
                          .reduce((acc, cur) => acc + cur.totAmt, 0),
                      totAmtByYear: 0,
                      isAggregation: true,
                      isAggregationFirst: false,
                    },
                    {
                      rentDivName: "년    계",
                      visit18hPer:
                        data.closeSalesRent
                          .filter(item =>
                            item.bsnDate.substring(0, 4) === this.searchOptions.bsnDate.substring(0, 4) &&
                            item.classCode2 === "REPAIR")
                          .reduce((acc, cur) => acc + cur.visit18hPer, 0),
                      visit9hPer:
                        data.closeSalesRent
                          .filter(item =>
                            item.bsnDate.substring(0, 4) === this.searchOptions.bsnDate.substring(0, 4) &&
                            item.classCode2 === "REPAIR")
                          .reduce((acc, cur) => acc + cur.visit9hPer, 0),
                      visit9hAditPer:
                        data.closeSalesRent
                          .filter(item =>
                            item.bsnDate.substring(0, 4) === this.searchOptions.bsnDate.substring(0, 4) &&
                            item.classCode2 === "REPAIR")
                          .reduce((acc, cur) => acc + cur.visit9hAditPer, 0),
                      netAmt:
                        data.closeSalesRent
                          .filter(item =>
                            item.bsnDate.substring(0, 4) === this.searchOptions.bsnDate.substring(0, 4) &&
                            item.classCode2 === "REPAIR")
                          .reduce((acc, cur) => acc + cur.netAmt, 0),
                      vatAmt:
                        data.closeSalesRent
                          .filter(item =>
                            item.bsnDate.substring(0, 4) === this.searchOptions.bsnDate.substring(0, 4) &&
                            item.classCode2 === "REPAIR")
                          .reduce((acc, cur) => acc + cur.vatAmt, 0),
                      totAmt: 0,
                      totAmtByMonth: 0,
                      totAmtByYear:
                        data.closeSalesRent
                          .filter(item =>
                            item.bsnDate.substring(0, 4) === this.searchOptions.bsnDate.substring(0, 4) &&
                            item.classCode2 === "REPAIR")
                          .reduce((acc, cur) => acc + cur.totAmt, 0),
                      isAggregation: true,
                      isAggregationFirst: false,
                    }
                  ]
                ),
            } :
            {
              bsnDate: this.searchOptions.bsnDate,
              dwName: getFormattedDate(this.searchOptions.bsnDate, "dddd"),
              totalSalesStatusInTop: data.totalSalesStatusInTop.filter((data) => (this.storeData.map((d) => d.comCode)).includes(data.storeCode)),
              totalSalesStatusInBottom: data.totalSalesStatusInBottom.map(item => ({
                ...item,
                visitPerson: item.visitPer,
              })),
              salesStatusByStore: data.salesStatusByStore.filter((data) => (this.storeData.map((d) => d.comCode)).includes(data.storeCode))
              ,
              depositStatus: data.closeAcmtlBasics.map(item => {
                return {
                  divName: item.acmtlSumDivName,
                  cash: item.payCash,
                  card: item.payCard,
                  precard: item.payPrecard,
                  entertainment: 0,
                  internalFrpy: 0,
                  credit: item.payCredit,
                  gift: item.payGift,
                  groupGift: item.payGroupGift,
                  deposit: item.payDeposit,
                  promotion: 0,
                  totalAmt: item.payTotAmt,
                  trans: item.payTrans,
                  balanceCredit: item.balanceCredit,
                  balancePrpy: item.balancePrpy,
                  balanceReturn: item.balanceReturn,
                  balanceTotAmt: item.balanceTotAmt,
                };
              }),
              sanctnList: data.sanctnDtls,
            }
      ;

      const postReportData = {
        reportJson: {
          jsonData: reportData,
          username: this.username,
          uniqueCode: this.$options.name,
        }
      };
      console.log("report DATA ====> ",reportData);
      this.$refs.reportViewComponent.postReport(
        postReportData,
        this.tabSelectedIdx === 1 ?
          "IncomAmountAggregationTableByFee" :
          this.tabSelectedIdx === 2 ?
            "IncomAmountAggregationTableByRent" :
            "IncomAmountAggregationTableByAll",
      );
    },
    onPrintButtonClicked() {
      this.$refs.reportViewComponent.printReport();
    },
    selectTab(args) {
      // if (args.selectedIndex === 2) {
      //   this.infoToast("대여수입 항목은 작업 중입니다");
      //   this.$refs.divTab.select(args.previousIndex ? args.previousIndex : 0);
      //   return;
      // }
      //
      // if (args.previousIndex === 2) {
      //   return;
      // }

      this.tabSelectedIdx = args.selectedIndex;

      this.searchButtonClicked();
    },
  }
};
</script>
